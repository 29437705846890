/* OwnerDashboard.css */

/* General Layout */
.owner-dashboard {
  color: #C0C0C0; /* Silver */
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #1c1c1c; /* Dark background for the dashboard */
  min-height: 100vh;
}

/* Header Styling */
.welcome-header {
  padding: 15px;
  text-align: left;
  color: #ecf0f1; /* Light color for better contrast */
  font-size: 2rem;
  font-weight: bold;
}

/* Grid Layout Overrides */
.layout {
  margin-top: 20px;
}

/* Dashboard Buttons */
.dashboard-button {
  background-color: #39FF14; /* Neon Green */
  color: #1C1C1C; /* Matte Black */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease-in-out;
}

.dashboard-button:hover {
  background-color: #00FFFF; /* Electric Cyan */
}

/* Subscription Info */
.subscription-info p {
  margin: 5px 0;
  color: #ecf0f1; /* Light text */
}

/* Error and Loading States */
.error,
.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #C0C0C0; /* Silver */
}

/* Adjusting Grid Item */
.layout > div {
  display: flex;
}

/* Onboarding Progress Bar */
.onboarding-progress-bar {
  background-color: #C0C0C0; /* Silver */
  border-radius: 5px;
  height: 20px;
  width: 100%;
  margin: 10px 0;
  overflow: hidden;
}

.onboarding-progress {
  background-color: #39FF14; /* Neon Green */
  height: 100%;
  transition: width 0.5s ease-in-out;
}

/* Remove default margin from GridLayout's children */
.react-grid-item {
  display: flex;
}

.react-grid-item > .card {
  width: 100%;
  min-height: 200px; /* Ensure minimum height */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .layout {
    width: 100% !important;
  }
}
