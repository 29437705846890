/* Container styling */
.sub-brand-management {
  background: #1f1f1f;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
  max-width: 90%;
  margin: 20px auto;
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
}

/* Heading */
.sub-brand-heading {
  text-align: center;
  margin-bottom: 20px;
  color: #00ffff;
}

/* Form styling */
.sub-brand-form {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-input, .form-select {
  min-width: 200px;
  background: #2c2c2c;
  border-radius: 4px;
}

/* List styling */
.sub-brand-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: white;
}

.sub-brand-list-item {
  border: 1px solid #00ffff;
  border-radius: 8px;
  padding: 10px;
  background: #000000;
}

/* Edit mode styling */
.sub-brand-edit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  color: white;
}

.edit-input, .edit-select {
  min-width: 150px;
  background: #2c2c2c;
  border-radius: 4px;
}

/* Collapsible working times section */
.collapsible-section {
  width: 100%;
  margin-top: 16px;
}

/* Logo section */
.logo-section {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.sub-brand-logo-large {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #00ffff;
}

/* Actions */
.edit-actions, .view-actions {
  display: flex;
  gap: 8px;
}

/* View mode styling */
.sub-brand-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sub-brand-info {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
}

.sub-brand-logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #00ffff;
  margin-right: 8px;
}
