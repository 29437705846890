.create-package-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
    position: absolute;
    z-index: 100;
  }
  
  .panel-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  button {
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .cancel-button {
    background: red;
    color: white;
  }
  