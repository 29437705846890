/* Navbar.css */

/* Updated Navbar Styles */
.navbar {
  background-color: #2c3e50; /* Darker background */
  box-shadow: 0 8px 16px rgba(105, 190, 216, 0.548); /* Enhanced shadow for floating effect */
  position: sticky;
  top: 20px; /* Slightly offset from the top for floating illusion */
  z-index: 1000;
  border-radius: 20px; /* Semi-circle edges */
  margin: 10px 20px; /* Space around the navbar to enhance floating look */
  backdrop-filter: blur(10px); /* Optional: adds a blur effect for modern look */
}

/* Center the navbar within the floating container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px; /* Increased padding for better spacing */
  height: 60px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Logo Styles */
.navbar-logo {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar-logo-image {
  height: 40px; /* Adjust based on your logo's aspect ratio */
  width: auto;
}

/* Navbar Links Container */
.navbar-links {
  display: flex;
  align-items: center;
}

/* Navbar Link Styles */
.navbar-link {
  margin: 0 12px;
  padding: 8px 16px;
  color: #bdc3c7; /* Subtle contrast color */
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 20px; /* Rounded edges for links */
  position: relative;
}

.navbar-link:hover {
  background-color: rgba(236, 240, 241, 0.2); /* Semi-transparent hover effect */
  color: #ecf0f1; /* Brighter color on hover for better readability */
}

/* Logout Button Styles */
.logout-btn {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  color: #bdc3c7; /* Consistent link color */
  padding: 8px 16px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.logout-btn:hover {
  background-color: rgba(236, 240, 241, 0.2);
  color: #ecf0f1;
}

/* Dropdown Styles */
.navbar-dropdown {
  position: relative;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  font: inherit;
  color: #bdc3c7; /* Consistent link color */
  padding: 8px 16px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-btn:hover {
  background-color: rgba(236, 240, 241, 0.2);
  color: #ecf0f1;
}

.arrow {
  border: solid #bdc3c7; /* Match arrow color with links */
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transition: transform 0.3s;
}

.down {
  transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
}

.dropdown-menu {
  position: absolute;
  top: 50px; /* Adjusted to align with the navbar's new position */
  left: 0;
  background-color: #34495e; /* Slightly different dark shade for dropdown */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  overflow: hidden;
  min-width: 160px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.dropdown-item {
  display: block;
  padding: 10px 15px;
  color: #ecf0f1; /* Light color for dropdown items */
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;
}

.dropdown-item:hover {
  background-color: rgba(236, 240, 241, 0.2);
  color: #ecf0f1;
}

/* FadeIn Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Styles */
.mobile-menu-icon {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  width: 25px;
  height: 3px;
  background-color: #bdc3c7; /* Match hamburger color with links */
  display: block;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  left: 0;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  top: 8px;
}

.hamburger.open {
  background-color: transparent;
}

.hamburger.open::before {
  transform: rotate(45deg);
  top: 0;
}

.hamburger.open::after {
  transform: rotate(-45deg);
  top: 0;
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: block;
  }

  .navbar-links {
    position: absolute;
    top: 80px; /* Adjusted to account for the margin */
    left: 20px;
    right: 20px;
    background-color: #2c3e50; /* Match navbar's dark background */
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }

  .navbar-links.active {
    max-height: 500px; /* Arbitrary large value to accommodate all links */
  }

  .navbar-link {
    width: 100%;
    margin: 10px 0;
    color: #ecf0f1; /* Ensure links are visible on dark background */
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    animation: none;
    min-width: 100%;
    background-color: #34495e; /* Consistent dropdown background */
    border-radius: 10px;
  }

  .dropdown-btn {
    width: 100%;
    justify-content: space-between;
    color: #ecf0f1; /* Ensure dropdown button is visible */
  }
}

/* Logo Image Responsiveness */
.navbar-logo-image {
  height: 40px; /* Adjust based on your logo's aspect ratio */
  width: auto;
  transition: height 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .navbar-logo-image {
    height: 35px; /* Slightly smaller on mobile */
  }
}
