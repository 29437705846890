/* Onboarding Wizard Styles */
.onboarding-step {
    background-color: #1C1C1C; /* Matte Black */
    color: #C0C0C0; /* Silver */
    font-family: 'Arial', sans-serif;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .progress-bar {
    background-color: #C0C0C0; /* Silver */
    border-radius: 5px;
    height: 20px;
    margin: 20px 0;
  }
  
  .progress {
    background-color: #39FF14; /* Neon Green */
    height: 100%;
    transition: width 0.5s ease-in-out;
  }
  
  .wizard-button {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .wizard-button:hover {
    background-color: #00FFFF; /* Electric Cyan */
    transition: 0.3s ease-in-out;
  }
  
  .dialog {
    background-color: #708090; /* Slate Gray */
    color: #1C1C1C; /* Matte Black */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .dialog-button {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
  }
  
  .dialog-button:hover {
    background-color: #00FFFF; /* Electric Cyan */
    transition: 0.3s ease-in-out;
  }
  