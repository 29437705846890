.pricing-square-foot {
    background: #1C1C1C;
    border-radius: 8px;
    padding: 15px;
    margin: 15px auto;
    color: white;
    width: 80%;
}

.header {
    text-align: left;
    margin: 10px;
    font-size: 18px;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.pricing-table th,
.pricing-table td {
    border: 1px solid white;
    padding: 8px;
    text-align: center;
    position: relative; /* For input alignment */
}

.pricing-table td input {
    width: calc(100% - 10px); /* Adjust input width to fit within the cell */
    height: 25px;
    border: none;
    outline: none;
    text-align: center;
    background: transparent;
    color: white;
}

.pricing-table td input:focus,
.pricing-table td input.edit-mode {
    border: 1px solid #00FFFF; /* Border when in edit mode */
    background: #333; /* Slightly darker background for input in edit mode */
}

.pricing-table td span {
    display: block;
    font-size: 14px;
    color: white;
    text-align: center;
    margin-top: 4px;
}

.edit-btn,
.save-btn {
    background-color: #00FFFF;
    color: black;
    border: none;
    padding: 8px 15px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
}

.add-btn {
    background-color: #39FF14;
    color: black;
    border: none;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 14px;
}

.remove-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
}
