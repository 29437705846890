.bay-management {
    background-color: #1C1C1C;
    color: #C0C0C0;
    padding: 20px;
    border-radius: 10px;
  }
  
  .header {
    color: #39FF14;
    margin-bottom: 20px;
  }
  
  .add-bay-button {
    background-color: #39FF14;
    color: #1C1C1C;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .bay-list {
    margin-top: 20px;
    padding: 0;
    list-style-type: none;
  }
  
  .bay-item {
    background-color: #333;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .bay-info {
    margin: 5px 0;
  }
  
  .edit-button {
    background-color: #00FFFF;
    color: #1C1C1C;
    margin-right: 10px;
  }
  
  .delete-button {
    background-color: #FF6347;
    color: #1C1C1C;
  }
  
  button:hover {
    opacity: 0.9;
  }
  