.admin-service-management {
    padding: 16px;
    background-color: #000000;
  }
  
  .button-group {
    display: flex;
    gap: 16px;
  }
  
  .service-list {
    margin-right: 16px;
    border-right: 1px solid #000000;
    padding-right: 16px;
  }
  
  .service-details, .sub-service-management {
    padding-left: 16px;
  }
  