/* AdminDashboard.css */

/* General Layout */
.admin-dashboard {
    background-color: #1C1C1C; /* Matte Black */
    color: #C0C0C0; /* Silver */
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
    padding: 20px;
  }
  
  /* Header Styling */
  .admin-dashboard h1 {
    background-color: #39FF14; /* Neon Green */
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    color: #1C1C1C; /* Matte Black */
    font-size: 2rem;
    font-weight: bold;
  }
  
  /* Section Headers */
  .admin-dashboard h2 {
    color: #39FF14; /* Neon Green */
    margin-top: 30px;
    font-size: 1.5rem;
  }
  
 /* Buttons */
.admin-dashboard button {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
    border: none;
    border-radius: 5px;
    padding: 10px; /* Simplify padding for consistency */
    font-size: 0.9rem; /* Slightly smaller font size for tables */
    font-weight: bold;
    cursor: pointer;
    margin: 5px;
    text-align: center; /* Ensure text is centered */
    min-width: 80px; /* Set a minimum width for buttons */
    height: 40px; /* Set a fixed height for better alignment */
    line-height: 20px; /* Center-align text vertically within the button */
  }
  
  /* Button Hover Effect */
  .admin-dashboard button:hover {
    background-color: #00FFFF; /* Electric Cyan */
    color: #1C1C1C; /* Matte Black */
    transition: 0.3s ease-in-out;
  }
  
  /* Tables */
  .admin-dashboard table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #1C1C1C; /* Matte Black */
    color: #C0C0C0; /* Silver */
  }
  
  .admin-dashboard th,
  .admin-dashboard td {
    border: 1px solid #708090; /* Slate Gray */
    padding: 10px;
    text-align: left;
  }
  
  .admin-dashboard th {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
  }
  
  .admin-dashboard tr:hover {
    background-color: #00FFFF; /* Electric Cyan */
    color: #1C1C1C; /* Matte Black */
    cursor: pointer;
  }
  
  /* Loading and Error Messages */
  .admin-dashboard .loading,
  .admin-dashboard .error {
    text-align: center;
    font-size: 1.2rem;
    color: #C0C0C0; /* Silver */
  }
  