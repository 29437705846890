.brand-card {
    width: 98%;
    max-width: 1200px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    margin: 15px auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  
  .brand-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .brand-header {
    font-size: 20px;
    font-weight: bold;
    justify-content: center;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .add-subbrand-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .add-subbrand-btn:hover {
    background-color: #218838;
  }
  
 
  