/* PublicSchedulerWizard.css */

.publicSchedulerWizard {
    padding: 16px;
    background-color: #1C1C1C;
    color: #C0C0C0;
    position: relative;
    font-family: Arial, sans-serif;
}

.publicSchedulerBreadcrumbs {
    margin-bottom: 24px;
}

.publicSchedulerBreadcrumbs a {
    cursor: pointer;
    color: #00FFFF;
    text-decoration: none;
}

.publicSchedulerBreadcrumbs .stepTitle {
    margin-left: 8px;
}

.vehicleSelection {
    margin-bottom: 32px;
}

.vehicleSelectionButton {
    margin-bottom: 16px;
}

.selectedVehicleDisplay {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.selectedVehicleDisplay .vehicleText {
    color: #39FF14;
}

.selectedVehicleDisplay .changeLink {
    margin-left: 16px;
    color: #00FFFF;
    cursor: pointer;
    text-decoration: underline;
}

.mainLayout {
    /* Grid container styles if needed */
}

.leftColumn {
    /* Uses Grid: 8/12 width */
}

.rightColumn {
    /* Uses Grid: 4/12 width */
}

/* Service Tabs */
.serviceTabs .MuiTabs-indicator {
    background-color: #39FF14;
}

.serviceTab {
    text-transform: none;
    border-radius: 4px;
}

.serviceTab.active {
    border: 2px solid #39FF14;
}

/* Panel Selection */
.panelSelection {
    margin-top: 16px;
}

.panelSelectionHeader {
    margin-bottom: 8px;
    color: #39FF14;
}

.panelSelectionSubHeader {
    margin-bottom: 16px;
    color: #00FFFF;
}

.panelButton {
    text-transform: none;
    border: 1px solid #ccc;
    margin: 4px;
    padding: 4px 8px;
    background-color: white;
    color: #1C1C1C;
    cursor: pointer;
    transition: background-color 0.3s;
}

.panelButton:hover {
    background-color: #eee;
}

.panelButton.selected {
    background-color: teal;
    color: white;
}

.submitButton {
    margin-top: 16px;
}

/* Recommended Packages Table */
.recommendedPackagesTable {
    margin-top: 16px;
}

.recommendedPackagesHeader {
    margin-top: 16px;
    margin-bottom: 16px;
    color: #00FFFF;
}

/* Appointment Preview Sidebar */
.appointmentPreview {
    position: sticky;
    top: 20px;
    background-color: #222;
    padding: 16px;
    border-radius: 4px;
}

.appointmentPreviewItem {
    margin-bottom: 8px;
    padding: 8px;
    background-color: #333;
    border-radius: 4px;
    position: relative;
}

.appointmentPreviewItem .removeButton {
    position: absolute;
    top: 0;
    right: 0;
    min-width: auto;
    padding: 0 4px;
    color: #ff5555;
    background: transparent;
    border: none;
    cursor: pointer;
}

/* Footer Note */
.footerNote {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #00FFFF;
}
