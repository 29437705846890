.list-packages-container {
    padding: 20px;
    color: white;
  }
  
  .subbrand-package-table {
    margin-bottom: 30px;
    border: 1px solid white;
    padding: 10px;
  }
  
  .subbrand-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .package-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1c1c1c; /* Dark background */
  }
  
  .package-table th, 
  .package-table td {
    border: 1px solid white; /* White grid lines */
    padding: 8px;
    text-align: center;
    color: white;
  }
  
  .package-table th {
    font-size: 18px;
    font-weight: bold;
  }
  
  .package-table .row-title {
    font-weight: bold;
    font-size: 16px;
  }
  
  .edit-icon, .delete-icon {
    margin-left: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .edit-icon:hover, .delete-icon:hover {
    transform: scale(1.2);
    color: #00ffff; /* Electric Cyan color on hover */
  }
  
  .edit-icon:hover::after {
    content: " Edit";
    font-size: 14px;
    margin-left: 5px;
  }
  
  .delete-icon:hover::after {
    content: " Delete";
    font-size: 14px;
    margin-left: 5px;
  }
  