/* General Calendar Styling */
.rbc-calendar {
    background-color: #1C1C1C; /* Dark matte background for the overall calendar */
    color: #C0C0C0; /* Silver text for labels and details */
    border-radius: 5px;
}
.rbc-day-bg-closed {
    background-color: #FF0000 !important; /* Bright red for closed days */
    color: #FFFFFF !important; /* White text */
    pointer-events: none; /* Prevent interaction */
    opacity: 0.6; /* Slight transparency to visually indicate unavailability */
}

/* Calendar Header (Toolbar) Styling */
.rbc-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: #39FF14; /* Neon Green for titles and text */
}

.rbc-toolbar-label {
    color: #00FFFF; /* Electric Cyan for the current month/week label */
    font-weight: bold;
}

/* Calendar Navigation Arrows and View Buttons (placed on the right) */
.rbc-toolbar button {
    color: #39FF14;
    background: none;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 5px;
}

.rbc-toolbar button:hover {
    color: #00FFFF; /* Electric Cyan for hover state */
}

.rbc-btn-group {
    display: flex;
    gap: 8px;
}

.rbc-btn-group button {
    color: #C0C0C0;
    background: none;
    border: 1px solid #39FF14; /* Neon green borders for view buttons */
    margin: 0 2px;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.rbc-btn-group button:hover {
    background-color: #00FFFF; /* Electric Cyan background on hover */
    color: #1C1C1C;
}

.rbc-btn-group .rbc-active {
    background-color: #39FF14; /* Active button with neon green */
    color: #1C1C1C;
}

/* Calendar Event Styles */
.rbc-event {
    background-color: #39FF14 !important; /* Neon Green background for events */
    color: #1C1C1C !important; /* Black text for events */
    border: none;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 0.9rem;
}

/* Calendar Time Slot Backgrounds */
.rbc-time-slot {
    background-color: #333; /* Dark gray for time slots */
}

.rbc-time-header {
    background-color: #333;
    border-bottom: 1px solid #00FFFF; /* Electric Cyan border to separate headers */
}

.rbc-day-bg {
    background-color: #1C1C1C;
}

/* Past Dates - Not Selectable */
.rbc-day-bg.past-date {
    background-color: #707070; /* Gray for past dates */
    pointer-events: none; /* Disable selection of past dates */
}

/* Available Days - Lime Green */
.rbc-day-bg.available-date {
    border: 2px solid #39FF14; /* Lime Green outline to indicate available days */
}

/* Fully Booked Days */
.rbc-day-bg.booked-date {
    background-color: rgba(255, 0, 0, 0.2); /* Light red overlay to indicate booked days */
}

/* Highlight Today */
.rbc-today {
    background-color: rgba(0, 191, 255, 0.2); /* Light blue overlay to highlight today */
    border: 2px solid #00BFFF; /* Light blue outline for today */
}

/* Highlight Day on Click */
.rbc-day-slot.rbc-selected {
    background-color: rgba(0, 255, 255, 0.2); /* Light cyan background when clicked */
}

/* Event on hover */
.rbc-event:hover {
    background-color: #00FFFF !important; /* Electric Cyan for hover on events */
    color: #1C1C1C !important;
}

/* Selected Slot Styling */
.rbc-selected-cell {
    background-color: rgba(0, 255, 255, 0.2); /* Light cyan to show the selected time slot */
}

/* Weekday Labels */
.rbc-header {
    background-color: #1C1C1C;
    color: #39FF14; /* Neon Green for the weekdays label */
    border-bottom: 1px solid #00FFFF;
    font-weight: bold;
}

/* Month View Styling */
.rbc-month-view {
    border-spacing: 5px;
    background-color: #1C1C1C;
}

.rbc-month-row {
    background-color: #1C1C1C;
}

.rbc-off-range-bg {
    background-color: #333; /* Dark gray for off-range days (days not in the current month) */
}

.rbc-off-range {
    color: #707070; /* Gray for dates not in the current month */
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #333; /* Subtle grid lines for the day slot */
}

/* Agenda View Styling */
.rbc-agenda-content {
    background-color: #1C1C1C;
    color: #C0C0C0;
}

.rbc-agenda-table {
    border-collapse: collapse;
    width: 100%;
}

.rbc-agenda-table th,
.rbc-agenda-table td {
    border: 1px solid #00FFFF;
    padding: 8px;
}

.rbc-agenda-table th {
    background-color: #333;
    color: #39FF14;
}
