.invoice-form {
    padding: 20px;
    background-color: #1C1C1C; /* Matte Black */
    color: #C0C0C0; /* Silver */
    border-radius: 8px;
    max-width: 1240px;
    margin: 0 auto;
}

.form-title {
    color: #39FF14; /* Neon Green */
    text-align: center;
    margin-bottom: 20px;
}

.form-section-horizontal {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.customer-selection {
    flex: 2;
    margin-right: 20px;
}

.invoice-info-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border: 1px solid #708090; /* Slate Gray */
    border-radius: 8px;
    background-color: #2a2a2a;
}

.invoice-info-box .form-group {
    margin-bottom: 5px;
}

.form-group label {
    display: block;
    color: #00FFFF; /* Electric Cyan */
    font-size: 0.85rem;
    margin-bottom: 5px;
}

.form-group select,
.form-group input[type="number"],
.form-group input[type="date"],
.form-group input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #708090; /* Slate Gray */
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #C0C0C0;
    font-size: 0.9rem;
}

.form-section,
.invoice-item {
    margin-bottom: 20px;
}

.item-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.add-item-button {
    width: 10%;
    padding: 5px;
    font-size: 1rem;
    color: #1C1C1C; /* Matte Black text */
    background-color: #39FF14; /* Neon Green */
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button {
    width: 10%;
    padding: 5px;
    font-size: 1rem;
    display: flex;
    color: #1C1C1C; /* Matte Black text */
    background-color: #39FF14; /* Neon Green */
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-item-button:hover, .submit-button:hover {
    background-color: #00FFFF; /* Electric Cyan */
}

.subtotal-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2em;
}

.selected-customer {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #39FF14; /* Neon Green */
}

.change-customer-button {
    padding: 6px 12px;
    background-color: #00FFFF; /* Electric Cyan */
    color: #1C1C1C;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.change-customer-button:hover {
    background-color: #39FF14;
}

.sales-tax {
    display: flex;
    justify-content: flex-end;
    align-items: Center;
    font-size: 1.2em;  
}

@media (max-width: 768px) {
    .form-section-horizontal {
        flex-direction: column;
    }

    .customer-selection {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .item-row {
        flex-direction: column;
    }

    .form-group select, .form-group input[type="number"] {
        font-size: 0.8rem;
    }
}
