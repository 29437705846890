/* src/components/Card/Card.css */

.card {
    background-color: #2c3e50; /* Dark background */
    color: #ecf0f1; /* Light text for contrast */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .card-header {
    padding: 10px 15px;
    background-color: #34495e; /* Header background */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: move; /* Indicate draggable area */
    display: flex;
    align-items: center;
  }
  
  .card-header h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .card-body {
    padding: 15px;
    overflow: auto; /* Enable scrolling if content overflows */
    flex-grow: 1;
  }
  