/* InvoiceDashboard.css */
.invoice-dashboard {
    background-color: #1C1C1C; /* Matte Black */
    color: #C0C0C0; /* Silver */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(57, 255, 20, 0.2);
  }
  
  .invoice-dashboard h2 {
    font-size: 2rem;
    color: #39FF14; /* Neon Green */
    text-shadow: 0px 0px 10px rgba(57, 255, 20, 0.5);
    margin-bottom: 1.5rem;
  }
  
  .button-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .invoice-dashboard .btn-primary {
    background-color: #00FFFF; /* Electric Cyan */
    color: #1C1C1C; /* Matte Black */
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .invoice-dashboard .btn-secondary {
    background-color: #00FFFF; /* Slate Gray */
    color: #1C1C1C; /* Matte Black */
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .invoice-dashboard .btn-primary:hover {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
  }
  
  .invoice-dashboard .btn-secondary:hover {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
  }
  
  .invoice-list {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #2F4F4F; /* Slate Gray for a slightly contrasting area */
    border-radius: 8px;
  }
  
  .invoice-list ul {
    list-style: none;
    padding: 0;
  }
  
  .invoice-list li {
    padding: 0.75rem;
    border-bottom: 1px solid #708090; /* Slate Gray */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .invoice-list li:last-child {
    border-bottom: none;
  }
  
  .invoice-list a {
    color: #00FFFF; /* Electric Cyan */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .invoice-list a:hover {
    color: #39FF14; /* Neon Green */
  }
  
  .invoice-list button {
    background: none;
    color: #C0C0C0; /* Silver */
    border: 1px solid #708090;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .invoice-list button:hover {
    background-color: #39FF14; /* Neon Green */
    color: #1C1C1C; /* Matte Black */
  }
  