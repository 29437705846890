.service-card {
    width:80% auto;
    margin: 20px auto; /* Centers the card */
    padding: 20px;
    border: 1px solid rgba(105, 190, 216, 0.548);
    border-radius: 8px;
    background-color: #2c3e50;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-header h4 {
    margin: 0;
    color: #ecf0f1;
}

.brand-actions input {
    margin-right: 8px;
}

.brands-list {
    margin-top: 16px;
}

.brand-card {
    margin-top: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    padding: 10px;
}

button {
    background-color: #27ae60;
    color: rgb(139, 204, 230);
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #219150;
}
