/* Base styling for the invoice list container */
.invoice-list {
    background-color: #1C1C1C;
    color: #C0C0C0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 6px 10px rgba(0, 255, 20, 0.2);
  }
  
  /* Header styling */
  .invoice-list h3 {
    color: #39FF14;
    font-size: 1.5rem;
    margin-bottom: 15px;
    border-bottom: 2px solid #39FF14;
    padding-bottom: 8px;
  }
  
  /* Invoice list items */
  .invoice-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .invoice-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    margin-bottom: 10px;
    background-color: #2C2C2C;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  /* Hover effect for each invoice item */
  .invoice-list li:hover {
    background-color: #333333;
    transform: scale(1.02);
  }
  
  /* Link styling */
  .invoice-list a {
    color: #00FFFF;
    text-decoration: none;
    font-weight: 500;
  }
  
  .invoice-list a:hover {
    color: #39FF14;
    text-decoration: underline;
  }
  
  /* Button styling */
  .invoice-list button {
    background-color: transparent;
    color: #FF6347;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .invoice-list button:hover {
    color: #FF4500;
  }
  
  .invoice-list button:first-child {
    color: #00FFFF;
    margin-right: 8px;
  }
  
  .invoice-list button:first-child:hover {
    color: #39FF14;
  }
  
  /* Empty state text */
  .invoice-list p {
    color: #708090;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
  }
  