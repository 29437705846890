.service-dashboard {
    margin: 20px;
}


.tabs button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #f5f5f5;
    transition: background 0.3s, color 0.3s;
}

.tabs button.active {
    background: #007bff;
    color: white;
}

.tabs button:hover {
    background: #e0e0e0;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #007bff;
    color: white;
    transition: background 0.3s;
}

button:hover {
    background: #0056b3;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}



.service-card h4 {
    margin: 0;
    font-size: 1.6em;
    color: #2fa1c4;
    align-items: center;
    justify-content: center;
}

.service-card .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.service-card .actions button {
    padding: 6px 12px;
    border-radius: 4px;
}

.service-card .brand-list {
    margin-top: 15px;
    list-style: none;
    padding: 10px;
}

.service-card .brand-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.service-card .brand-list li button {
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
}

.service-card .brand-list li button:hover {
    background-color: #c82333;
}

.service-card .add-brand-dropdown {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.service-card .add-brand-dropdown select {
    flex-grow: 1;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.service-card .add-brand-dropdown button {
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
}

.service-card .add-brand-dropdown button:hover {
    background-color: #218838;
}
.dashboard-tabs {
    margin-bottom: -5px; /* Slightly overlaps the tab content to hide the bottom border */
    display: flex;
}

.dashboard-tabs button {
    display: flex;
    padding: 10px 20px;
    background: white; 
    color: #333;
    cursor: pointer;
    transition: all 0.3s;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.dashboard-tabs button.active {
    background: white; /* Blends with the white background */
    color: black; /* Changes font color for the active tab */
    border-bottom: 5px solid white; /* Removes visual bottom border */
    font-weight: bold;
}

.dashboard-tabs button:hover {
    background: #c1c1c1; /* Slightly lighter on hover */
}

.tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 0 5px 5px 5px; /* Top corners handled by buttons */
    background: white;
}
