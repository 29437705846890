.package-management {
    margin-top: 20px;
    border-radius: 8px;
    padding: 15px;
    margin: 15px auto;
    color: white;
    width: 80%;
    background-color: #1C1C1C; /* Matte Black Theme */
    color: #00FFFF; /* Electric Cyan */
}

.package-management h3 {
    color: #39FF14; /* Neon Green */
    margin-bottom: 10px;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    position: relative;
    z-index: 10;
}

.package-container {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #00FFFF;
    border-radius: 6px;
    background-color: #1C1C1C;
}

.package-management table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.package-management th, .package-management td {
    padding: 8px;
    border: 1px solid #00FFFF; /* Electric Cyan */
    text-align: center;
}

.create-package-container {
    background-color: #1C1C1C;
    border: 2px solid #00FFFF;
    padding: 15px;
    border-radius: 8px;
    margin-top: 10px;
    position: relative;
    z-index: 5;
}

.package-management button {
    background-color: #39FF14;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    color: black;
    font-weight: bold;
    transition: all 0.3s;
}

.package-management button:hover {
    background-color: #00FFFF;
}

.package-table {
    width: 100%;
    margin-top: 10px;
}

.package-table th, .package-table td {
    padding: 10px;
    border: 1px solid #00FFFF;
}

.package-table input {
    width: 80%;
    padding: 5px;
    background-color: #1C1C1C;
    color: #C0C0C0;
    border: 1px solid #00FFFF;
}
