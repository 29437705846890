.vehicle-search-mini {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%
;
  }
  
  select {
    padding: 5px;
    margin: 5px 0;
    width: 25%;
  }
  
  button {
    width: 25%;
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .vehicle-thumbnail img {
    width: 50px;
    height: auto;
  }
  
  .thumbnail-preview {
    width: 100%;  /* Adjust this value to make the thumbnail smaller */
    height: auto;  /* Maintain aspect ratio */
    margin: 10px 0;  /* Add some spacing */
  }
  
  .thumbnail-preview img {
    width: 100%;  /* Make sure the image fits within the container */
    height: auto;
    object-fit: contain;  /* Ensure the image fits without distortion */
  }
  
  /* Container holding all swatches */
.color-swatch-container {
    display: flex;       /* Align swatches in a row */
    flex-wrap: wrap;     /* Allow wrapping if there are too many swatches */
    gap: 10px;           /* Space between each swatch */
    margin: 10px 0;      /* Add spacing around the container */
  }
  
  /* Individual swatch */
  .color-swatch {
    width: 10px;         /* Adjust this value to change swatch size */
    height: 10px;        /* Make it a square */
    border-radius: 50%;  /* Make it circular */
    border: 2px solid #000; /* Optional border to make swatches stand out */
    cursor: pointer;     /* Show pointer on hover */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); /* Optional shadow for better visibility */
    transition: transform 0.2s ease-in-out;  /* Add a smooth hover effect */
  }
  
  /* Hover effect for swatches */
  .color-swatch:hover {
    transform: scale(1.1);  /* Slightly enlarge the swatch on hover */
  }