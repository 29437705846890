/* SalesTax.css */
.sales-tax-form, .sales-tax-list {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #1C1C1C; /* Matte Black */
    color: #C0C0C0; /* Silver */
    border-radius: 8px;
  }
  
  h2 {
    color: #39FF14; /* Neon Green */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group, .tax-item {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    color: #00FFFF; /* Electric Cyan */
    margin-bottom: 5px;
  }
  
  input, select {
    width: 100%;
    padding: 8px;
    border: 1px solid #708090; /* Slate Gray */
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #C0C0C0;
  }
  
  button, .add-button {
    padding: 10px;
    font-size: 1rem;
    color: #1C1C1C; /* Matte Black text */
    background-color: #39FF14; /* Neon Green */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    
  }
  
  button:hover, .add-button:hover {
    background-color: #00FFFF; /* Electric Cyan */
  }
  