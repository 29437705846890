.manage-service-brands {
  margin-top: 20px;
}

.associated-brands-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Adds spacing between rows and columns */
  list-style: none;
  padding: 15px;
  background-color: rgba(91, 153, 172, 0.548);
  border-radius: 5px;
}

.associated-brands-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.associated-brands-list li:hover {
  transform: scale(1.02);
}

.remove-brand-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: bold;
}

.remove-brand-button:hover {
  background-color: #c82333;
}

.add-brand-dropdown {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.add-brand-dropdown select {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.add-brand-dropdown button {
  padding: 10px 14px;
  border-radius: 5px;
}

.add-brand-dropdown button:nth-child(2) {
  background-color: #28a745;
  color: white;
}

.add-brand-dropdown button:nth-child(2):hover {
  background-color: #218838;
}

button.close-manage-brands {
  padding: 10px 16px;
  font-size: 1rem;
  background-color: #179b00;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin: 20px auto 0;
  cursor: pointer;
  display: block;
}

@media (max-width: 768px) {
  .associated-brands-list li {
    width: 100%;
  }

  .add-brand-dropdown {
    flex-direction: column;
  }
}
