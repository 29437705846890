.vehicle-search-container {
  margin: 20px;
}

.search-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.search-fields select {
  padding: 5px;
  margin-right: 10px;
}

.color-swatch-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.color-swatch {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
}

.color-swatch:hover::after {
  content: attr(title); /* Display the color title on hover */
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 5px;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 3px;
}
